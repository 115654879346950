<template>
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.19598 0.315674H13.6082C14.3814 0.315674 15.0103 0.944489 15.0103 1.71771V10.13C15.0103 10.9032 14.3814 11.532 13.6082 11.532H5.19598C4.42276 11.532 3.79395 10.9032 3.79395 10.13V1.71771C3.79395 0.944489 4.42276 0.315674 5.19598 0.315674ZM10.1031 6.62485H12.2062V5.22281H10.1031V3.11975H8.70108V5.22281H6.59802V6.62485H8.70108V8.72791H10.1031V6.62485ZM10.804 14.3361H2.39179C1.61856 14.3361 0.989746 13.7073 0.989746 12.9341V4.52185H2.39179V12.9341H10.804V14.3361Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconAddNewItem'
}
</script>
